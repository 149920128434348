export var TodoStatus;
(function (TodoStatus) {
    TodoStatus["TODO"] = "todo";
    TodoStatus["COMPLETED"] = "completed";
})(TodoStatus || (TodoStatus = {}));
export var TodoPriority;
(function (TodoPriority) {
    TodoPriority["URGENT"] = "urgent";
    TodoPriority["HIGH"] = "high";
    TodoPriority["NORMAL"] = "normal";
    TodoPriority["LOW"] = "low";
    TodoPriority["NONE"] = "none";
})(TodoPriority || (TodoPriority = {}));
export var TodoTabs;
(function (TodoTabs) {
    TodoTabs["ASSIGNED_TO_ME"] = "Assigned To me";
    TodoTabs["CREATED_BY_ME"] = "Created By me";
    TodoTabs["ALL_TODOS"] = "All the todos";
})(TodoTabs || (TodoTabs = {}));
export var COLUMN_TITLE;
(function (COLUMN_TITLE) {
    COLUMN_TITLE["TODO"] = "To Do";
    COLUMN_TITLE["DONE"] = "Done";
})(COLUMN_TITLE || (COLUMN_TITLE = {}));
